export const reactBackendUrl = process.env.REACT_APP_BACKEND_URL;
// export const reactBackendUrl = "http://localhost:8000";

export const truncateString = (t, size = 29) => {
  if (t) {
    return t.length > size ? t.substring(0, size) + "..." : t;
  }
  return "--";
};

export const formatDateTimeString = (dateString) => {
  const date = new Date(dateString);
  const options = { month: "short", day: "numeric", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  const day = date.getDate();
  const daySuffix =
    day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th";
  return formattedDate.replace(/(\d+),/, `$1${daySuffix},`);
};

export const salutationOptions = ["Mr.", "Ms.", "Mrs.", "Dr.", "Prof."];

export const formatDateToTextDate = (dateString) => {
  if (dateString) {
    let date;
    const isDDMMYYYY = /^\d{2}-\d{2}-\d{4}$/.test(dateString);

    if (isDDMMYYYY) {
      const [day, month, year] = dateString.split("-");
      date = new Date(`${year}-${month}-${day}`);
    } else {
      date = new Date(dateString);
    }

    const options = { year: "numeric", month: "short", day: "numeric" };
    let formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

    const day = date.getDate();
    const daySuffix =
      day % 10 === 1 && day !== 11
        ? "st"
        : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
        ? "rd"
        : "th";

    formattedDate = formattedDate.replace(/\b(\d{1,2})\b/, `$1${daySuffix}`);
    return formattedDate;
  } else {
    return "--";
  }
};

export const formatTimesheetDateToTextDate = (dateString) => {
  if (dateString) {
    let date;
    const isDDMMYYYY = /^\d{2}-\d{2}-\d{4}$/.test(dateString);

    if (isDDMMYYYY) {
      const [month, day, year] = dateString.split("-");
      date = new Date(`${year}-${month}-${day}`);
    } else {
      const isMMDDYYYY = /^\d{2}-\d{2}-\d{4}$/.test(dateString);
      if (isMMDDYYYY) {
        const [month, day, year] = dateString.split("-");
        date = new Date(`${year}-${month}-${day}`);
      } else {
        date = new Date(dateString);
      }
    }
    if (isNaN(date)) {
      return "--";
    }

    const options = { year: "numeric", month: "short", day: "numeric" };
    let formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

    const dayNumber = date.getDate();
    const daySuffix =
      dayNumber % 10 === 1 && dayNumber !== 11
        ? "st"
        : dayNumber % 10 === 2 && dayNumber !== 12
        ? "nd"
        : dayNumber % 10 === 3 && dayNumber !== 13
        ? "rd"
        : "th";

    formattedDate = formattedDate.replace(/\b(\d{1,2})\b/, `$1${daySuffix}`);
    return formattedDate;
  } else {
    return "--";
  }
};

export const isEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isImage = (file) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "svg"];
  const extension = file.split(".").pop().toLowerCase();
  return imageExtensions.includes(extension);
};

export const truncateFilename = (file) => {
  const maxFilenameLength = 70;
  const ext = file.name.split(".").pop();
  const timestamp = Date.now().toString();
  const baseFilenameLength = maxFilenameLength - ext.length - 1;

  const baseFilename = file.name.slice(
    0,
    baseFilenameLength - timestamp.length - 1
  );
  const newFilename = `${baseFilename}_${timestamp}.${ext}`;

  return new File([file], newFilename, { type: file.type });
};

export const isValidGst = (str) => {
  let regex = new RegExp(
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
  );

  if (!str) {
    return false;
  }

  if (regex.test(str) === true) {
    return true;
  } else {
    return false;
  }
};

const CurrencyFormatter = (amount, currency, locale = "de-DE") => {
  const formattedAmount = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(amount);

  return formattedAmount;
};

export const formatPrice = (price, currency = "INR", currencySymbol) => {
  if (price !== undefined && price !== null) {
    let priceStr = parseFloat(price).toFixed(2);
    let [integerPart, decimalPart] = priceStr.split(".");
    let formattedPrice = price;
    if (currency === "EUR") {
      let amount = CurrencyFormatter(parseFloat(price), currency);
      formattedPrice = amount.slice(0, -1);
    } else if (currency && currency !== undefined && currency === "INR") {
      let lastThreeDigits = integerPart.slice(-3);
      let otherDigits = integerPart.slice(0, -3);
      if (otherDigits !== "") {
        lastThreeDigits = "," + lastThreeDigits;
      }
      integerPart =
        otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

      formattedPrice = decimalPart
        ? `${integerPart}.${decimalPart}`
        : `${integerPart}.00`;
    } else if (currency && currency !== "INR") {
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      formattedPrice = decimalPart
        ? `${integerPart}.${decimalPart}`
        : `${integerPart}.00`;
    } else {
      formattedPrice = price;
    }
    if (currencySymbol) {
      return `${currencySymbol}${formattedPrice}`;
    } else {
      return `${currency} ${formattedPrice}`;
    }
  }
};

export const getMediaUrl = (url) => {
  if (!url) {
    return null;
  }
  if (url.startsWith("/media")) {
    return reactBackendUrl + url;
  }

  if (!url.startsWith("https://") && !url.startsWith("http://")) {
    return `${reactBackendUrl}${url}`;
  }
  return url;
};

export const ckEditorConfiguration = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "heading",
      "|",
      "fontfamily",
      "fontsize",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "strikethrough",
      "subscript",
      "superscript",
      "code",
      "|",
      "link",
      "blockQuote",
      "codeBlock",
      "|",
      "bulletedList",
      "numberedList",
      "todoList",
      "outdent",
      "indent",
    ],
    shouldNotGroupWhenFull: false,
    placeholder: "",
  },
};

export const preventInvalidNumberInput = (e) => {
  if (["e", "E", "-", "+"].includes(e.key)) {
    e.preventDefault();
  }
};
