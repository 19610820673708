import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/Shared/NavBar";
import Footer from "./components/Shared/Footer";
import FallbackLoading from "./components/Shared/FallbackLoading";
import { useSelector } from "react-redux";
import NotFound from "./components/Shared/NotFound";

const Auth = lazy(() => import("./pages/Auth/Auth"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const EmployeeManagement = lazy(() =>
  import("./pages/Employees/EmployeeManagement")
);
const Employee = lazy(() => import("./pages/Employees/Employee"));
const TeamManagement = lazy(() => import("./pages/Employees/TeamManagement"));
const RoleManagament = lazy(() => import("./pages/Employees/RoleManagement"));

const Clients = lazy(() => import("./pages/Clients/Clients"));
const Client = lazy(() => import("./pages/Clients/Client"));
const Projects = lazy(() => import("./pages/Projects/Projects"));
const Project = lazy(() => import("./pages/Projects/Project"));

const Vendors = lazy(() => import("./pages/Vendors/Vendors"));
const Vendor = lazy(() => import("./pages/Vendors/Vendor"));

const MyAccount = lazy(() => import("./pages/Account/MyAccount"));
const BankAccounts = lazy(() => import("./pages/Account/BankAccounts"));
const Invoices = lazy(() => import("./pages/Invoices/Invoices"));
const RecurringInvoices = lazy(() =>
  import("./pages/Invoices/RecurringInvoices")
);
const Invoice = lazy(() => import("./pages/Invoices/Invoice"));
const AddInvoice = lazy(() => import("./pages/Invoices/AddInvoice"));
const Leaves = lazy(() => import("./pages/Leaves/Leaves"));
const ExpenseRecords = lazy(() => import("./pages/Expense/ExpenseRecords"));
const ProjectReports = lazy(() => import("./pages/Reports/ProjectReports"));
const PaymentReports = lazy(() => import("./pages/Reports/PaymentReports"));

const Routers = () => {
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  const user = useSelector((state) => state.authReducer.user);

  return (
    <>
      <Suspense fallback={<FallbackLoading />}>
        <Router>
          {isAuthenticated && <NavBar />}
          <Routes>
            {!isAuthenticated && (
              <>
                <Route
                  path="/"
                  element={
                    <Auth type="login" isAuthenticated={isAuthenticated} />
                  }
                />
                <Route
                  path="/forgot"
                  element={
                    <Auth type="forgot" isAuthenticated={isAuthenticated} />
                  }
                />
                <Route
                  path="/forgot-verify"
                  element={
                    <Auth
                      type="forgot-verify"
                      isAuthenticated={isAuthenticated}
                    />
                  }
                />
                <Route
                  path="/forgot-reset"
                  element={
                    <Auth
                      type="forgot-reset"
                      isAuthenticated={isAuthenticated}
                    />
                  }
                />
              </>
            )}
            {isAuthenticated && (
              <>
                <Route path="/" element={<Dashboard />} />

                <Route path="projects">
                  <Route index element={<Projects />} />
                  <Route path=":id" element={<Project />}>
                    <Route path="milestones" element={<Project />} />
                    <Route path="team" element={<Project />} />
                    <Route path="timesheets" element={<Project />} />
                    <Route path="documents" element={<Project />} />
                    <Route path="invoices" element={<Project />} />
                    <Route path="expenses" element={<Project />} />
                  </Route>
                </Route>
                <Route path="account" element={<MyAccount />} />

                {/* Admin Only Routes */}
                {user?.is_admin && (
                  <>
                    <Route path="employeemanagement">
                      <Route index element={<EmployeeManagement />} />
                      <Route path=":id" element={<Employee />} />
                    </Route>
                    <Route path="clients">
                      <Route index element={<Clients />} />
                      <Route path=":id" element={<Client />} />
                    </Route>
                    <Route path="invoices">
                      <Route index element={<Invoices />} />
                      <Route path=":id" element={<Invoice />} />
                      <Route path="add" element={<AddInvoice />} />
                      <Route path="add/:id" element={<AddInvoice />} />
                    </Route>
                    <Route path="recurring_invoices">
                      <Route index element={<RecurringInvoices />} />
                      <Route path=":id" element={<Invoice />} />
                      <Route path="add" element={<AddInvoice />} />
                      <Route path="add/:id" element={<AddInvoice />} />
                    </Route>
                    <Route path="/expenses">
                      <Route index element={<ExpenseRecords />} />
                    </Route>
                    <Route path="teammanagement" element={<TeamManagement />} />
                    <Route path="rolemanagement" element={<RoleManagament />} />

                    <Route path="leaves" element={<Leaves />} />
                    <Route path="vendors">
                      <Route index element={<Vendors />} />
                      <Route path=":id" element={<Vendor />} />
                    </Route>
                    <Route path="bank-accounts" element={<BankAccounts />} />
                    <Route
                      path="reports/project"
                      element={<ProjectReports />}
                    />
                    <Route
                      path="reports/payments"
                      element={<PaymentReports />}
                    />
                  </>
                )}
              </>
            )}
            <Route path="*" element={<NotFound />} />
          </Routes>
          {isAuthenticated && <Footer />}
        </Router>
      </Suspense>
    </>
  );
};
export default Routers;
